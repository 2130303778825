@font-face {
  font-family: halebopmedium;
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.eot");
  src: url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.eot?#iefix") format("embedded-opentype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.woff2") format("woff2"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.woff") format("woff"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium.ttf") format("truetype"), url("https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.svg#halebopmedium") format("svg");
}

:root {
  --font-family: "halebopmedium";
  --main-layer-background: #c1e0e0;
  --second-layer-background: #f3f7f8;
  --line-color: #000;
  --primary-color: #fdf138;
  --primary-hover-color: #94f0cb;
  --primary-text-color: #222;
  --expressive-button: #0c8d0c;
  --expressive-button-hover: #0fd50f;
  --expressive-button-focus: #13cc13;
  --expressive-button-disabled: #a0a0a9;
  --expressive-button-text-color: #fff;
  --secondary-button: #fd3737;
  --secondary-button-border: #7d86b2;
  --secondary-button-hover: #500d09;
  --secondary-button-dark: #d64b44;
  --secondary-button-disabled: #a0a0a9;
  --secondary-button-text-color: #222;
  --secondary-text-color-hover: #fff;
  --destructive-dark: #fd9337, ;
}

/*# sourceMappingURL=index.a6e27ea5.css.map */
