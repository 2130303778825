@font-face {
  font-family: 'halebopmedium';
  src: url('https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.eot');
  src: url('https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.woff2')
      format('woff2'),
    url('https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.woff')
      format('woff'),
    url('https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium.ttf')
      format('truetype'),
    url('https://d2hwldi6w8hju1.cloudfront.net/Content/fonts/halebop_medium-webfont.svg#halebopmedium')
      format('svg');
}

:root {
  --font-family: 'halebopmedium';

  --main-layer-background: #c1e0e0;
  --second-layer-background: #f3f7f8;
  --line-color: #000;

  --primary-color: #fdf138;
  --primary-hover-color: #94f0cb;
  --primary-text-color: #222222;

  /* DEPRECATED purchase-button */
  /*--purchase-button: #ffffff;*/
  /*--purchase-button-hover: #94f0cb;*/
  /*--purchase-button-focus: #81d5b3;*/
  /*--purchase-button-disabled: #797c7b;*/
  /*--purchase-button-text-color: #000000;*/

  /* DEPRECATED destructive */
  /*--destructive: #fd3737;*/
  /*--destructive-hover: #d64b44;*/
  /*--destructive-disabled: #d64b44;*/
  /*--destructive-text-color: #000000;*/

  --expressive-button: #0c8d0c;
  --expressive-button-hover: #0fd50f;
  --expressive-button-focus: #13cc13;
  --expressive-button-disabled: #a0a0a9;
  --expressive-button-text-color: #ffffff;

  --secondary-button: #fd3737;
  --secondary-button-border: #7d86b2;
  --secondary-button-hover: #500d09;
  --secondary-button-dark:  #d64b44;
  --secondary-button-disabled: #a0a0a9;
  --secondary-button-text-color: #222222;
  --secondary-text-color-hover: #ffffff;

  --destructive-dark: #fd9337,
}
